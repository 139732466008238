import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react-v2/24/outline';
import {
  ChevronDownIcon,
  LogoutIcon,
  MenuIcon,
  XIcon,
} from '@heroicons/react/outline';
import { UserCircleIcon } from '@heroicons/react/solid';
import clsx from 'clsx';
import Link from 'next/link';
import { Button, Typography } from '@leaf/ui';
import { NavigationItem } from '@/components/layouts/root';
import TickerPrice from '@/components/layouts/root/ticker-price';
import routes from '@/utils/routes';

interface Props {
  handleLogout(): void;
  isLoggedIn?: boolean;
  navMenuItems?: NavigationItem[];
  open: boolean;
  setOpen: (open: boolean) => void;
  username?: string | null;
}

const MobileMenuButton: React.ComponentType<Props> = ({
  handleLogout,
  isLoggedIn,
  navMenuItems,
  open,
  setOpen,
  username,
}) => {
  const BaseMobileLink = ({ item }: { item: NavigationItem }) => {
    return item.external ? (
      <a
        key={item.name}
        className="typography-subtitle-2 flex w-full items-center justify-between space-x-2 whitespace-nowrap p-4"
        href={item.href}
        rel="noopener noreferrer"
        target="_blank"
      >
        <div>{item.name}</div>
        <ArrowTopRightOnSquareIcon className="h-4 w-4" />
      </a>
    ) : (
      <Link
        key={item.name}
        className="typography-subtitle-2 w-full items-center justify-between p-4"
        href={item.href}
      >
        {item.name}
      </Link>
    );
  };
  const AccountTabs = [
    {
      href: routes.accountSettings.href,
      name: 'Account settings',
    },
    ...(username
      ? [
          {
            href: routes.profile.href(username),
            name: 'Profile',
          },
        ]
      : []),
  ];

  const RenderMobileLink = ({ nav }: { nav: NavigationItem }) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggleOpen = () => setIsOpen((prev) => !prev);
    if (nav.children && nav.children?.length > 0) {
      return (
        <div className="w-full">
          <div
            className={clsx(
              'typography-subtitle-2 flex w-full flex-row items-center justify-between p-4',
              isOpen && 'border-b'
            )}
            onClick={toggleOpen}
          >
            {nav.name}
            <ChevronDownIcon
              className={`size-5 transition-transform ${
                isOpen && 'rotate-180'
              }`}
            />
          </div>
          {isOpen && (
            <div className="flex flex-col gap-2 rounded border-b bg-hubs-background-accent ">
              {nav.children.map((item) => (
                <BaseMobileLink key={item.name} item={item} />
              ))}
            </div>
          )}
        </div>
      );
    }
    return <BaseMobileLink item={nav} />;
  };

  return (
    <>
      <button
        className="inline-flex lg:hidden"
        type="button"
        onClick={() => setOpen(true)}
      >
        <MenuIcon className="h-6 w-6 text-hubs-secondary" />
      </button>
      <Transition.Root as={Fragment} show={open}>
        <Dialog as="div" className="relative z-50" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-x-0 bottom-0 top-[72px] bg-primary-off-black/50 transition-opacity" />
          </Transition.Child>

          <div className="inset-x-0 bottom-0 top-[72px] overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed bottom-0 right-0 top-[72px] flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto relative w-[260px] sm:w-[300px]">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-in-out duration-500"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-500"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="absolute -left-10 top-4 flex">
                        <button
                          className="rounded-md text-white focus:outline-none focus:ring-2 focus:ring-white"
                          type="button"
                          onClick={() => setOpen(false)}
                        >
                          <span className="sr-only">Close panel</span>
                          <XIcon aria-hidden="true" className="size-5" />
                        </button>
                      </div>
                    </Transition.Child>
                    <div className="flex h-full flex-col overflow-y-auto bg-hubs-background">
                      <div className="flex-1">
                        <div className="flex items-center gap-1 p-4 md:p-8">
                          <TickerPrice showListingKey version="mobile" />
                        </div>

                        <div className="mt-4 h-px bg-secondary-grey-light" />

                        <div className="mt-4 flex flex-col items-start gap-2">
                          {navMenuItems?.map((nav) => (
                            <RenderMobileLink key={nav.name} nav={nav} />
                          ))}
                        </div>
                      </div>

                      <div className="flex flex-col gap-4 border-t p-4 md:px-8">
                        {isLoggedIn ? (
                          <>
                            <Typography className="typography-subtitle-2 inline-flex items-center gap-2 font-bold text-hubs-secondary">
                              My account
                              <UserCircleIcon className="h-6 w-6" />
                            </Typography>
                            {AccountTabs.map((tab) => (
                              <a
                                key={tab.name}
                                className={clsx(
                                  'typography-subtitle-2 inline-flex h-8 w-full flex-row items-center justify-between gap-2 text-company-primary'
                                )}
                                href={tab.href}
                                onClick={() => setOpen(false)}
                              >
                                {tab.name}
                              </a>
                            ))}
                            <button
                              className="typography-subtitle-2 flex h-[52px] w-full items-center gap-1 text-status-red hover:bg-hubs-background-accent"
                              type="button"
                              onClick={handleLogout}
                            >
                              <LogoutIcon className="h-6 w-6" />
                              Sign out
                            </button>
                          </>
                        ) : (
                          <>
                            <Link
                              className="block"
                              href={routes.auth.signup.href()}
                            >
                              <Button fullWidth>Sign up</Button>
                            </Link>
                            <Link
                              className="block"
                              href={routes.auth.signin.href()}
                            >
                              <Button fullWidth color="grey" variant="tertiary">
                                Log in
                              </Button>
                            </Link>
                          </>
                        )}
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default MobileMenuButton;
