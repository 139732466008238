import { useCallback, useEffect, useMemo, useState } from 'react';
import analytics from '@analytics';
import { Menu } from '@headlessui/react';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react-v2/24/solid';
import { LogoutIcon } from '@heroicons/react/outline';
import { UserCircleIcon } from '@heroicons/react/solid';
import clsx from 'clsx';
import cookie from 'cookie';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { maybeAddRelNofollow } from '@leaf/helpers';
import {
  Button,
  Typography,
  NavItem,
  DUMMY_LOGO_URL,
  FooterBadge,
  FooterNewsletterSubscription,
  FooterSiteMapLinkGroup,
  FooterDescription,
  SubscribeForm as NewsletterSubscriptionForm,
  WebinarBanner,
  Spinner,
} from '@leaf/ui';
import { useCurrentInvestorUserQuery } from '@/apollo/generated';
import { CurrentCompanyQuery } from '@/apollo/generated';
import CookieBanner from '@/components/cookie-manager/cookie-banner';
import HeadTag from '@/components/layouts/head-tag';
import MobileMenuButton from '@/components/layouts/root/mobile-menu-button';
import TickerPrice from '@/components/layouts/root/ticker-price';
import NotificationCenter from '@/components/notification-center';
import CloudinaryImage from '@/components/utils/cloudinary/image';
import EmailPopupModal, {
  EMAIL_POPUP_CLOSED_COOKIE_NAME,
} from '@/components/website/email-popup-modal';
import ExitPreview from '@/components/website/exit-preview';
import SubscribeForm from '@/components/website/layout/subscribe-form';
import { useAlert } from '@/contexts/alert-context';
import { useCookieBanner } from '@/contexts/cookie-banner-context';
import { useCurrentCompany } from '@/contexts/current-company-context';
import { useWebsite } from '@/contexts/website-context';
import { useCreateTrackingUtmEvent } from '@/hooks/use-create-tracking-event';
import useFeatureEnabled, { FLAGS } from '@/hooks/use-feature-toggles';
import { logout } from '@/utils/auth/auth-helpers';
import GoogleOneTap from '@/utils/google-one-tap';
import {
  clearReturnToCookie,
  setReturnToCookie,
} from '@/utils/redirect-helpers';
import routes from '@/utils/routes';

type NavigationItem = {
  children?: NavigationItem[];
  current?: boolean;
  external?: boolean;
  href: string;
  id: string;
  name: string;
  openInNewTab?: boolean;
};

interface WebsiteLayoutProps {
  children?: React.ReactNode;
  hideFooterOnMobileAnnouncement?: boolean;
}

type FooterLinkProps = {
  children: React.ReactNode;
  href?: string;
  onClick?: () => void;
  rel?: string;
};

const Footer = dynamic(() => import('./dynamic/footer'), {
  ssr: false,
});

const FooterLink = ({ children, href, onClick, rel }: FooterLinkProps) => (
  <a
    className="hover:opacity-90"
    href={href}
    rel={rel}
    target="_blank"
    onClick={onClick}
  >
    {children}
  </a>
);

const FooterDivider = () => <span>•</span>;

const getFooterLinks = ({
  getCompanyDefaultEmail,
  isUK,
  listingKey,
  marketKey,
  marketListingKey,
  setCookieBannerVisible,
}: {
  getCompanyDefaultEmail: () => string;
  isUK: boolean;
  listingKey: string;
  marketKey: string;
  marketListingKey: string;
  setCookieBannerVisible: (visible: boolean) => void;
}) => {
  const relNofollow = `noreferrer ${maybeAddRelNofollow(marketListingKey)}`;

  const isSunrice = marketKey === 'asx' && listingKey === 'sgl';
  const isBango = marketKey === 'lse' && listingKey === 'bgo';

  const defaultLinks = [
    <FooterLink key="ih" href="https://www.investorhub.com" rel={relNofollow}>
      Powered by InvestorHub
    </FooterLink>,
    <FooterLink
      key="terms"
      href={
        isUK
          ? 'https://investorhub.com/uk-microsite-terms-and-conditions'
          : 'https://investorhub.com/au-microsite-terms-and-conditions'
      }
      rel={relNofollow}
    >
      Terms and conditions
    </FooterLink>,
    <FooterLink
      key="privacy"
      href={
        isUK
          ? 'https://investorhub.com/uk-microsite-privacy-policy'
          : 'https://investorhub.com/au-microsite-privacy-policy'
      }
      rel={relNofollow}
    >
      Privacy policy
    </FooterLink>,
  ];

  const sunriceLinks = [
    <FooterLink key="ih" href="https://www.investorhub.com" rel={relNofollow}>
      InvestorHub
    </FooterLink>,
    <FooterLink
      key="sunrice-terms"
      href="https://www.sunrice.com.au/website-terms-of-use"
      rel={relNofollow}
    >
      SunRice Terms of use
    </FooterLink>,
    <FooterLink
      key="ih-terms"
      href="https://investorhub.com/au-microsite-terms-and-conditions"
      rel={relNofollow}
    >
      InvestorHub Terms of use
    </FooterLink>,
    <FooterLink
      key="sunrice-privacy"
      href="https://www.sunrice.com.au/privacy-policies"
      rel={relNofollow}
    >
      SunRice Privacy policy
    </FooterLink>,
    <FooterLink
      key="ih-privacy"
      href="https://investorhub.com/au-microsite-privacy-policy"
      rel={relNofollow}
    >
      InvestorHub Privacy policy
    </FooterLink>,
    <FooterLink
      key="collection"
      href="https://res.cloudinary.com/investorhub/image/upload/v1731619070/leaf-prod/283/manual_uploads/20241113_Personal_Information_Collection_Statement_-_Shareholders_-_InvestorHub_f9tulv.pdf"
      rel={relNofollow}
    >
      Collection Statement
    </FooterLink>,
  ];

  const bangoLinks = [
    ...defaultLinks,
    ...[
      <FooterLink
        key="bango-terms"
        href="https://bango.com/terms/"
        rel={relNofollow}
      >
        Bango Terms of use
      </FooterLink>,
    ],
  ];

  const commonLinks = [
    <span
      key="cookie"
      className="hover:cursor-pointer hover:opacity-90"
      onClick={() => setCookieBannerVisible(true)}
    >
      Cookie settings
    </span>,
    <FooterLink
      key="tech"
      href={`mailto:${getCompanyDefaultEmail()}?subject=Technical%20Issue%20-%20${marketKey.toUpperCase()}%3A${listingKey.toUpperCase()}&body=Any%20data%20provided%20will%20be%20solely%20used%20to%20process%20your%20request%2C%20which%20may%20take%20up%20to%2030%20days.%0A%0APlease%20provide%20the%20details%20of%20your%20issue%20here%3A`}
      rel="noopener noreferer"
    >
      Technical issues?
    </FooterLink>,
  ];

  const links = [
    ...(isSunrice ? sunriceLinks : isBango ? bangoLinks : defaultLinks),
    ...commonLinks,
  ];

  return links.reduce<React.ReactNode[]>((acc, link, i) => {
    if (i === 0) return [link];
    return [...acc, <FooterDivider key={`divider-${i}`} />, link];
  }, []);
};

const WebsiteLayout: React.ComponentType<WebsiteLayoutProps> = ({
  children,
  hideFooterOnMobileAnnouncement,
}) => {
  const { formatAndShowError } = useAlert();

  const { currentCompany, isQmodEnabled, isUK, translate } =
    useCurrentCompany();

  const {
    investorHub,
    isUnlisted,
    liveWebinar,
    name,
    recentScheduledWebinar,
    ticker: { listingKey, marketKey, marketListingKey },
  } = currentCompany;

  const { currentWebsite, isEmailPopupOpen, setIsEmailPopupOpen } =
    useWebsite();

  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const { cookieBannerVisible, setCookieBannerVisible } = useCookieBanner();

  const currentUserQuery = useCurrentInvestorUserQuery();

  const [isSignInLoading, setIsSignInLoading] = useState(false);

  const isGoogleOneTapEnabled =
    useFeatureEnabled(FLAGS.googleOneTap) &&
    !currentUserQuery.loading &&
    !currentUserQuery.data?.currentInvestorUser &&
    !isSignInLoading;

  const handleGoogleOneTapLoading = () => {
    setIsSignInLoading(true);
    setTimeout(() => {
      setIsSignInLoading(false);
      formatAndShowError(
        'Something went wrong with your sign in. Please try again.'
      );
    }, 30000);
  };

  const router = useRouter();
  const { asPath, pathname, query } = router;

  const isWebinarPage = pathname.includes('/webinars/');

  const isSunrice = marketKey === 'asx' && listingKey === 'sgl';

  useCreateTrackingUtmEvent();

  useEffect(() => {
    const setupComplete =
      currentUserQuery.data?.currentInvestorUser?.confirmedAt &&
      currentUserQuery.data?.currentInvestorUser?.username;
    if (
      asPath &&
      asPath !== '/' &&
      !asPath.includes(routes.auth.signup.href()) &&
      !setupComplete
    )
      setReturnToCookie(asPath);
    if (setupComplete || (asPath && asPath.includes(routes.auth.signup.href())))
      clearReturnToCookie();
  }, [asPath, query, router, currentUserQuery.data?.currentInvestorUser]);

  type Child = NonNullable<
    NonNullable<
      NonNullable<CurrentCompanyQuery['currentCompany']>['publishedWebsite']
    >['parentNavMenuItems']
  >[0];

  const navMenuItems: NavigationItem[] | undefined = useMemo(() => {
    if (!currentWebsite?.parentNavMenuItems) return [];

    const isCurrentPage = (
      slug: string | null | undefined,
      url: string | null | undefined,
      children?: Array<{ page?: { slug?: string | null }; url?: string | null }>
    ): boolean => {
      if (children && children.length > 0) {
        return children.some((child) =>
          isCurrentPage(child.page?.slug, child.url)
        );
      }
      if (url) return false;
      if (slug === 'home' && pathname === '/_companies/[host]') return true;
      if (slug && pathname.includes(slug)) return true;
      if (url && pathname === url) return true;
      return query.slug === slug;
    };

    const createNavigationItem = (navMenuItem: Child): NavigationItem => ({
      children: navMenuItem?.children
        ?.slice()
        .sort((a, b) => (a?.position ?? 0) - (b?.position ?? 0))
        .map(createNavigationItem),
      current: isCurrentPage(
        navMenuItem?.page?.slug,
        navMenuItem?.url,
        navMenuItem?.children?.map((child) => ({
          page: { slug: child?.page?.slug },
          url: child?.url,
        }))
      ),
      external:
        navMenuItem?.openInNewTab ||
        (!navMenuItem?.page &&
          !!navMenuItem?.url &&
          navMenuItem?.url.includes('http')),
      href:
        navMenuItem?.page?.slug === 'home'
          ? '/'
          : navMenuItem?.page?.slug
          ? `/${navMenuItem?.page?.slug}`
          : navMenuItem?.url ?? '',
      id: navMenuItem?.id ?? '',
      name: navMenuItem?.label ?? '',
      openInNewTab: navMenuItem?.openInNewTab,
    });

    return currentWebsite.parentNavMenuItems
      .slice()
      .sort((a, b) => (a?.position ?? 0) - (b?.position ?? 0))
      .filter(
        (item, index, self) =>
          index === self.findIndex((t) => t?.id === item?.id)
      )
      .map(createNavigationItem);
  }, [currentWebsite, pathname, query.slug]);

  const handleLogout = useCallback(async () => {
    try {
      await logout();

      window.location.assign(routes.index.href);
    } catch (error) {
      formatAndShowError(error);
    }
  }, [formatAndShowError]);

  const getCompanyDefaultEmail = () => {
    if (isUK) {
      return 'ukclients@investorhub.com';
    }

    return 'clients@investorhub.com';
  };

  useEffect(() => {
    if (currentWebsite?.isEmailPopupModalEnabled && !isEmailPopupOpen) {
      const cookies = cookie.parse(document.cookie);
      const emailPopupClosed =
        cookies[EMAIL_POPUP_CLOSED_COOKIE_NAME] === 'true';
      const isSurveyPage =
        pathname.includes('/announcements/') ||
        pathname.includes('/activity-updates/');

      if (!emailPopupClosed) {
        const timer = setTimeout(
          () => {
            setIsEmailPopupOpen(true);
            analytics.track('email_popup_modal_displayed');
          },
          isSurveyPage ? 60000 : 15000
          // 1 minute delay for announcements and activity updates, 15 seconds for other pages
        );

        return () => clearTimeout(timer);
      }
    }
  }, [
    currentWebsite?.isEmailPopupModalEnabled,
    pathname,
    setIsEmailPopupOpen,
    isEmailPopupOpen,
  ]);

  function maybeShowTicker() {
    {
      /*
        Experiment for non-publicly-listed companies:
        + When company_profile is non publicly listed (isUnlisted)
        + then the <PriceTicker /> in navbar is hidden
      */
    }

    if (isUnlisted) return null;

    return (
      <>
        <TickerPrice showListingKey version="desktop-two-lines" />
        <div className="h-8 w-px bg-hubs-dividers" />
      </>
    );
  }

  return (
    <>
      {process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID && (
        <div className="flex items-center justify-center">
          <GoogleOneTap
            clientId={process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID}
            enabled={!!isGoogleOneTapEnabled}
            onGoogleOneTapLoading={handleGoogleOneTapLoading}
          />
        </div>
      )}
      {currentWebsite?.googleTagManagerId && (
        <noscript>
          <iframe
            height="0"
            src={`https://www.googletagmanager.com/ns.html?id=${currentWebsite.googleTagManagerId}`}
            style={{ display: 'none', visibility: 'hidden' }}
            width="0"
          />
        </noscript>
      )}
      <HeadTag />
      <div className="flex min-h-screen flex-col">
        <header className="fixed inset-x-0 top-0 z-20 bg-hubs-background">
          {/* Uptime check element - do not remove */}
          <div className="hidden" id="hub2-uptime-check" />
          {!isWebinarPage && currentWebsite?.showWebinarBanner && (
            <>
              {liveWebinar ? (
                <WebinarBanner isLive={true} webinar={liveWebinar} />
              ) : recentScheduledWebinar ? (
                <WebinarBanner
                  isLive={false}
                  webinar={recentScheduledWebinar}
                />
              ) : null}
            </>
          )}
          <div className="relative mx-auto flex h-[72px] max-w-screen-xl items-center gap-4 px-4 sm:gap-6 sm:px-6">
            <div className="flex min-w-0 flex-1 items-center gap-4">
              <Link className="flex" href={routes.index.href}>
                {currentWebsite?.logoCloudinaryUrl ? (
                  <CloudinaryImage
                    alt="Logo"
                    className="h-[46px] w-auto"
                    height={50}
                    src={currentWebsite?.logoCloudinaryUrl}
                    width={200}
                  />
                ) : (
                  <Image
                    alt="Dummy logo"
                    height={46}
                    src={DUMMY_LOGO_URL}
                    width={46}
                  />
                )}
              </Link>
              {currentWebsite?.isFullWebsiteReplacement &&
              !currentWebsite?.title ? null : (
                <>
                  <div className="h-8 w-px bg-hubs-dividers" />
                  <div className="font-heading text-hubs-secondary">
                    {currentWebsite?.title ?? `${name} investor hub`}
                  </div>
                </>
              )}
            </div>

            <div
              className={clsx(
                'hidden min-w-0 flex-1 items-center justify-end gap-4 lg:flex',
                currentUserQuery.loading && 'opacity-0'
              )}
            >
              {maybeShowTicker()}

              {currentUserQuery.data?.currentInvestorUser ? (
                <>
                  <NotificationCenter />
                  <div className="h-8 w-px bg-hubs-dividers" />
                  <Menu as="div" className="relative">
                    <Menu.Button className="typography-subtitle-2 inline-flex items-center gap-2 text-hubs-primary">
                      <span data-test="my-account-btn-text">My account</span>
                      <UserCircleIcon className="h-6 w-6" />
                    </Menu.Button>
                    <Menu.Items className="absolute right-0 z-30 mt-2 min-w-64 origin-top-right divide-y overflow-hidden rounded-md border bg-hubs-background shadow-md">
                      <Menu.Item>
                        <div className="p-4">
                          <Typography className="text-hubs-secondary">
                            Signed in as
                          </Typography>
                          <Typography
                            className="mt-1 text-hubs-secondary"
                            variant="subtitle-2"
                          >
                            {currentUserQuery.data.currentInvestorUser.email}
                          </Typography>
                        </div>
                      </Menu.Item>
                      <Menu.Item>
                        <div>
                          <Link
                            className="typography-subtitle-2 flex h-[52px] items-center px-4 hover:bg-hubs-background-accent"
                            href={routes.accountSettings.href}
                          >
                            Account settings
                          </Link>
                        </div>
                      </Menu.Item>
                      {currentUserQuery.data.currentInvestorUser.username ? (
                        <Menu.Item>
                          <div>
                            <Link
                              className="typography-subtitle-2 flex h-[52px] items-center px-4 hover:bg-hubs-background-accent"
                              href={routes.profile.href(
                                currentUserQuery.data.currentInvestorUser
                                  .username
                              )}
                            >
                              Profile
                            </Link>
                          </div>
                        </Menu.Item>
                      ) : null}
                      <Menu.Item>
                        <button
                          className="typography-subtitle-2 flex h-[52px] w-full items-center gap-1 px-4 text-status-red hover:bg-hubs-background-accent"
                          data-test="sign-out-nav-btn"
                          type="button"
                          onClick={handleLogout}
                        >
                          <LogoutIcon className="h-6 w-6" />
                          Sign out
                        </button>
                      </Menu.Item>
                    </Menu.Items>
                  </Menu>
                </>
              ) : isSignInLoading ? (
                <Spinner />
              ) : (
                <>
                  <Link href={routes.auth.signin.href()}>
                    <Button
                      color="grey"
                      variant="tertiary"
                      onClick={() => setReturnToCookie(router.asPath)}
                    >
                      Log in
                    </Button>
                  </Link>
                  <Link href={routes.auth.signup.href()}>
                    <Button
                      color="company"
                      testId="sign-up-nav-btn"
                      variant="primary"
                      onClick={() => setReturnToCookie(router.asPath)}
                    >
                      Sign Up
                    </Button>
                  </Link>
                </>
              )}
            </div>

            {currentUserQuery.data?.currentInvestorUser && (
              <div className="inline-flex lg:hidden">
                <NotificationCenter />
              </div>
            )}

            <MobileMenuButton
              handleLogout={handleLogout}
              isLoggedIn={!!currentUserQuery.data?.currentInvestorUser}
              navMenuItems={[
                ...navMenuItems,
                ...(currentWebsite?.isFullWebsiteReplacement
                  ? []
                  : [
                      {
                        current: false,
                        external: true,
                        href: investorHub?.website ?? routes.index.href,
                        name: `Back to ${name}`,
                      },
                    ]),
              ]}
              open={mobileNavOpen}
              setOpen={setMobileNavOpen}
              username={currentUserQuery.data?.currentInvestorUser?.username}
            />
          </div>

          {/* This absolute div goes underneath the div following, so that the grey background goes all the way to the edges */}
          <div className="bg-company-accent text-company-accent-text">
            <div className="z-10 mx-auto hidden min-h-[48px] max-w-screen-xl items-center gap-4 px-4 sm:gap-6 sm:px-6 md:py-1.5 lg:flex">
              <div className="flex items-center gap-4 lg:gap-6 xl:gap-8">
                {navMenuItems.map((item) => (
                  <NavItem key={item.id} item={item} />
                ))}
              </div>
              {currentWebsite?.isFullWebsiteReplacement ? null : (
                <>
                  <span className="grow"></span>
                  <Link
                    className="flex items-center space-x-2 whitespace-nowrap font-body text-sm text-company-accent-text"
                    href={investorHub?.website ?? routes.index.href}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <div>Back to {name} </div>
                    <ArrowTopRightOnSquareIcon className="h-4 w-4" />
                  </Link>
                </>
              )}
            </div>
          </div>
        </header>
        <main
          className={clsx(
            'flex flex-1 flex-col pt-[72px] lg:mt-[48px]',
            hideFooterOnMobileAnnouncement
              ? 'bg-hubs-background-accent'
              : 'bg-hubs-background'
          )}
        >
          {children}
        </main>
        {currentWebsite?.isCustomFooterEnabled ? (
          <Footer
            address={currentWebsite.address}
            badges={currentWebsite.badges as FooterBadge[]}
            customFooterLinks={
              <Typography
                className="flex flex-wrap items-center justify-center gap-1 text-footer-text-color sm:justify-start"
                component="div"
                variant="body-small"
              >
                {getFooterLinks({
                  getCompanyDefaultEmail,
                  isUK,
                  listingKey,
                  marketKey,
                  marketListingKey,
                  setCookieBannerVisible,
                })}
              </Typography>
            }
            customNewsletterSubscriptionPromptContent={
              isSunrice ? (
                <Typography variant="body-small">
                  By submitting your email address, you consent to the
                  collection, use and disclosure of your personal information by
                  SunRice and our IT partner InvestorHub in accordance with the{' '}
                  <a
                    className="underline"
                    href="https://res.cloudinary.com/investorhub/image/upload/v1731619070/leaf-prod/283/manual_uploads/sunrice-investorhub-collection-statement.pdf"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Collection Statement
                  </a>
                  . SunRice and InvestorHub collect personal information from
                  you when you interact with the SunRice Investor Hub (through
                  cookies and similar technologies), answer questions, surveys
                  or submit forms and other information. This includes
                  information collected from or about the device you use,
                  including IP address, webpages you have visited and your
                  browsing behaviour. We use your information for the purposes
                  set out in the Collection Statement, which includes to improve
                  user experience, create user profiles and deliver tailored
                  marketing content to you. Please see{' '}
                  <a
                    className="underline"
                    href="https://www.sunrice.com.au/au-privacy-policy"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    SunRice&apos;s Privacy Policy
                  </a>{' '}
                  and{' '}
                  <a
                    className="underline"
                    href="https://investorhub.com/au-microsite-privacy-policy"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    InvestorHub&apos;s Privacy Policy
                  </a>{' '}
                  for information about how to access, correct or submit a
                  privacy complaint about your personal information. Your emails
                  are managed by InvestorHub, an investor engagement platform.
                  Unsubscribe anytime.
                </Typography>
              ) : undefined
            }
            description={currentWebsite.description as FooterDescription}
            email={currentWebsite.email}
            facebookUrl={currentWebsite.facebookUrl}
            footerBackgroundColor={currentWebsite.footerBackgroundColor}
            footerButtonBackgroundColor={
              currentWebsite.footerButtonBackgroundColor
            }
            footerButtonTextColor={currentWebsite.footerButtonTextColor}
            footerLogoCloudinaryUrl={
              currentWebsite.footerLogoCloudinaryUrl ??
              currentWebsite.logoCloudinaryUrl
            }
            footerTextColor={currentWebsite.footerTextColor}
            instagramUrl={currentWebsite.instagramUrl}
            isNewsletterSubscriptionEnabled={
              currentWebsite.isNewsletterSubscriptionEnabled &&
              !currentUserQuery.loading &&
              !currentUserQuery.data?.currentInvestorUser
            }
            isQmodEnabled={isQmodEnabled}
            isSitemapEnabled={currentWebsite.isSitemapEnabled}
            isUK={isUK}
            linkedinUrl={currentWebsite.linkedinUrl}
            marketListingKey={marketListingKey}
            newsletterSubscription={
              currentWebsite.newsletterSubscription as FooterNewsletterSubscription
            }
            newsletterSubscriptionPrompt={
              <NewsletterSubscriptionForm
                buttonColor="footer"
                event="subscribed_from_embed"
                hasCustomText={isSunrice}
                isSimpleForm={
                  currentWebsite.newsletterSubscription
                    ? (
                        currentWebsite.newsletterSubscription as FooterNewsletterSubscription
                      ).isSimpleForm ?? true
                    : true
                }
                listingKey={listingKey}
                marketKey={marketKey}
              />
            }
            sitemapLinkGroups={
              currentWebsite.sitemapLinkGroups as FooterSiteMapLinkGroup[]
            }
            telephone={currentWebsite.telephone}
            twitterUrl={currentWebsite.twitterUrl}
          />
        ) : (
          <footer
            className={clsx(
              'bg-company-accent py-16 text-company-accent-text',
              !currentUserQuery.loading &&
                !currentUserQuery.data?.currentInvestorUser &&
                hideFooterOnMobileAnnouncement &&
                'hidden md:block'
            )}
          >
            <div className="mx-auto mb-8 grid max-w-screen-xl grid-cols-5 gap-6 px-4 sm:px-6">
              <div className="col-span-5 flex flex-col items-center gap-5 sm:min-h-[132px] md:col-span-2 md:items-start lg:col-span-3">
                <div className="mb-2 flex h-[64px] w-[64px] items-center justify-center overflow-hidden rounded-full bg-hubs-background">
                  {currentWebsite?.logoSquareCloudinaryUrl ? (
                    <CloudinaryImage
                      alt="Logo"
                      height={64}
                      quality={100}
                      src={currentWebsite?.logoSquareCloudinaryUrl}
                      width={64}
                    />
                  ) : (
                    <Image
                      alt="Logo"
                      height={64}
                      quality={100}
                      src={DUMMY_LOGO_URL}
                      width={64}
                    />
                  )}
                </div>

                <Typography
                  className="text-center font-body sm:text-left"
                  variant="subtitle-1"
                >
                  {currentWebsite?.isFullWebsiteReplacement ? (
                    name
                  ) : (
                    <span className="hidden sm:inline-block">
                      {currentWebsite?.title ?? `${name} investor hub`}
                    </span>
                  )}
                </Typography>
              </div>

              {!currentUserQuery.loading &&
                !currentUserQuery.data?.currentInvestorUser && (
                  <div className="col-span-5 md:col-span-3 lg:col-span-2">
                    <Typography
                      className="mb-2 text-center font-heading sm:text-left"
                      variant="display-small"
                    >
                      Stay in the loop.
                    </Typography>
                    <Typography className="mb-4 text-center font-body sm:text-left">
                      {/* Remove the trailing "." from the company name to avoid scenario ".." */}
                      Subscribe to receive{' '}
                      {translate('announcements.lowercase')} and updates from{' '}
                      {name.replace(/\.$/, '')}.
                    </Typography>
                    <SubscribeForm
                      event="subscribed_from_embed"
                      listingKey={listingKey}
                      marketKey={marketKey}
                    />
                  </div>
                )}
            </div>
            {/*
            When isQmodEnabled there is more contents on the left side, so made this section top aligned to balance
            Otherwise, there is one line on both side, so made them vertically centered
           */}
            <div
              className={clsx(
                'mx-auto mt-20 flex max-w-screen-xl flex-col-reverse items-center justify-between gap-6 border-t px-4 py-6 sm:flex-row sm:px-6',
                isQmodEnabled ? 'sm:items-start' : ''
              )}
            >
              <div className="space-y-4">
                <Typography
                  className="flex flex-wrap items-center justify-center gap-1 text-company-accent-text sm:justify-start"
                  component="div"
                  variant="body-small"
                >
                  {getFooterLinks({
                    getCompanyDefaultEmail,
                    isUK,
                    listingKey,
                    marketKey,
                    marketListingKey,
                    setCookieBannerVisible,
                  })}
                </Typography>

                {isQmodEnabled && (
                  <div>
                    <Typography
                      className="block text-center text-company-accent-text sm:hidden"
                      variant="body-xs"
                    >
                      TSXV Financial Market Data powered by{' '}
                      <Link
                        className="font-semibold"
                        href="https://www.quotemedia.com"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        Quotemedia.com
                      </Link>
                      <br />
                      All rights reserved •{' '}
                      <Link
                        className="font-semibold"
                        href="https://www.quotemedia.com/legal/tos"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        View the Terms of Use
                      </Link>
                      <br />
                      Data delayed 15 minutes unless indicated
                    </Typography>

                    <Typography
                      className="hidden text-company-accent-text sm:block"
                      variant="body-xs"
                    >
                      TSXV Financial Market Data powered by{' '}
                      <Link
                        className="font-semibold"
                        href="https://www.quotemedia.com"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        Quotemedia.com
                      </Link>
                      . All rights reserved
                      <br />
                      <Link
                        className="font-semibold"
                        href="https://www.quotemedia.com/legal/tos"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        View the Terms of Use
                      </Link>
                      {' • '} Data delayed 15 minutes unless indicated
                    </Typography>
                  </div>
                )}
              </div>

              <div className="flex gap-6 fill-company-accent-text">
                {investorHub?.twitter && (
                  <a
                    href={investorHub.twitter}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <svg
                      className="h-6 w-6 hover:opacity-90"
                      role="img"
                      viewBox="0 0 30 30"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <title>Twitter</title>
                      <path d="M26.37,26l-8.795-12.822l0.015,0.012L25.52,4h-2.65l-6.46,7.48L11.28,4H4.33l8.211,11.971L12.54,15.97L3.88,26h2.65 l7.182-8.322L19.42,26H26.37z M10.23,6l12.34,18h-2.1L8.12,6H10.23z" />
                    </svg>
                  </a>
                )}
                {investorHub?.linkedin && (
                  <a
                    href={investorHub.linkedin}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <svg
                      className="h-6 w-6 hover:opacity-90"
                      role="img"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <title>LinkedIn</title>
                      <path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z" />
                    </svg>
                  </a>
                )}
                {investorHub?.facebook && (
                  <a
                    href={investorHub.facebook}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <svg
                      className="h-6 w-6 hover:opacity-90"
                      role="img"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <title>Facebook</title>
                      <path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z" />
                    </svg>
                  </a>
                )}
                {investorHub?.instagram && (
                  <a
                    href={investorHub.instagram}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <svg
                      className="h-6 w-6 hover:opacity-90"
                      role="img"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <title>Instagram</title>
                      <path d="M12 0C8.74 0 8.333.015 7.053.072 5.775.132 4.905.333 4.14.63c-.789.306-1.459.717-2.126 1.384S.935 3.35.63 4.14C.333 4.905.131 5.775.072 7.053.012 8.333 0 8.74 0 12s.015 3.667.072 4.947c.06 1.277.261 2.148.558 2.913.306.788.717 1.459 1.384 2.126.667.666 1.336 1.079 2.126 1.384.766.296 1.636.499 2.913.558C8.333 23.988 8.74 24 12 24s3.667-.015 4.947-.072c1.277-.06 2.148-.262 2.913-.558.788-.306 1.459-.718 2.126-1.384.666-.667 1.079-1.335 1.384-2.126.296-.765.499-1.636.558-2.913.06-1.28.072-1.687.072-4.947s-.015-3.585-.072-4.85c-.06-1.277-.256-2.149-.558-2.913-.306-.789-.718-1.459-1.384-2.126C21.319 1.347 20.651.935 19.86.63c-.765-.297-1.636-.499-2.913-.558C15.667.012 15.26 0 12 0zm0 2.16c3.203 0 3.585.016 4.85.071 1.17.055 1.805.249 2.227.415.562.217.96.477 1.382.896.419.42.679.819.896 1.381.164.422.36 1.057.413 2.227.057 1.266.07 1.646.07 4.85s-.015 3.585-.074 4.85c-.061 1.17-.256 1.805-.421 2.227-.224.562-.479.96-.899 1.382-.419.419-.824.679-1.38.896-.42.164-1.065.36-2.235.413-1.274.057-1.649.07-4.859.07-3.211 0-3.586-.015-4.859-.074-1.171-.061-1.816-.256-2.236-.421-.569-.224-.96-.479-1.379-.899-.421-.419-.69-.824-.9-1.38-.165-.42-.359-1.065-.42-2.235-.045-1.26-.061-1.649-.061-4.844 0-3.196.016-3.586.061-4.861.061-1.17.255-1.814.42-2.234.21-.57.479-.96.9-1.381.419-.419.81-.689 1.379-.898.42-.166 1.051-.361 2.221-.421 1.275-.045 1.65-.06 4.859-.06l.045.03zm0 3.678c-3.405 0-6.162 2.76-6.162 6.162 0 3.405 2.76 6.162 6.162 6.162 3.405 0 6.162-2.76 6.162-6.162 0-3.405-2.76-6.162-6.162-6.162zM12 16c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm7.846-10.405c0 .795-.646 1.44-1.44 1.44-.795 0-1.44-.646-1.44-1.44 0-.794.646-1.439 1.44-1.439.793-.001 1.44.645 1.44 1.439z" />
                    </svg>
                  </a>
                )}
              </div>
            </div>
          </footer>
        )}
      </div>
      <CookieBanner />
      <ExitPreview />
      {!currentUserQuery.data?.currentInvestorUser &&
        currentWebsite?.isEmailPopupModalEnabled &&
        !cookieBannerVisible && (
          <EmailPopupModal
            companyName={currentCompany?.name}
            imageCloudinaryUrl={currentWebsite.emailPopupModalCloudinaryUrl}
            isOpen={isEmailPopupOpen}
            marketListingKey={marketListingKey}
            setIsOpen={setIsEmailPopupOpen}
          />
        )}
    </>
  );
};

export default WebsiteLayout;
